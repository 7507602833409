import "./App.css";
import {
  Box,
  Button,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "./img/logo.svg";
import { useState, useEffect } from "react";
import img1 from './img/2.webp'

const theme = createTheme({
  palette: {
    primary: {
      main: "#142866",
    },
    secondary: {
      main: "#3EC28B",
    },
  },
  typography: {
    fontFamily: "outfit",
  
  },

});

function App() {
  const [animate, setAnimate] = useState(false);

  const mobileTheme = useTheme()

  const isMobile = useMediaQuery(mobileTheme.breakpoints.down("md"))

  useEffect(() => {
    // Trigger the animation after the component mounts
    setAnimate(true);
  }, []);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
        className="hide_scrollbar"
          sx={{
            width: "100vw",
            bgcolor: "#EBF2FF",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          
            overflow:'hidden'
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: 1.7,
              mt:{lg:13, md:13, sm:10, xs:8}
            }}
          >
            <img src={logo} alt="Golvia_logo" width={40} />

            <Box
              sx={{ overflowX: "hidden", height: "40px" }}
              className="hide_scrollbar"
            >
              <Typography
                className={`text-container ${animate ? "animate" : ""}`}
                sx={{
                  fontSize: "30px",
                  fontWeight: 500,
                  height: "100%",
                  color: "#000",
                }}
              >
                Golvia
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mt: 6, width: {lg:"65%", md:'75%', sm:'80%', xs:'90%'} }}>
            <Typography
              sx={{
                color: "primary.main",
                fontSize: {lg:'45px' ,md:'40px', sm:'35px', xs:'25px'},
                fontWeight: 500,
                textAlign: "center",
                lineHeight:{lg: "60px", md:'60px', sm:'50px', xs:'30px'}
              }}
            >
              The one <span className="hero_span">social network</span> for
              <br />
              athletes, scouts and clubs to connect
            </Typography>
            <Typography
              sx={{ textAlign: "center", color: "#000000", fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'12px'}, mt:2 }}
            >
              Don’t miss out when we launch, join the queue to and get to know
            </Typography>
          </Box>

          <Box sx={{ mt: isMobile ? 3 : 4, width: {lg:"28%", md:"33%", sm:'50%', xs:'75%'}, display:'grid'  , placeItems:'center', }}>
            {
              isMobile ? (
                <>
                 <TextField
              fullWidth
              variant="standard"
              placeholder="Email Address"
              sx={{px:3, bgcolor:'#fff', borderRadius:'50px', py:1, border:'0.5px solid #D8E4FD', boxSizing:'border-box'}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "50px",
                  background: "#fff",
                  fontSize:'13px'
             
         
                },
                
                disableUnderline:true
              }}
            />
                <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        color: "#fff",
                        textTransform: "initial",
                        borderRadius: "50px",
                      width:'100px',
                 mt:2
                      }}
                    >
                      Join list{" "}
                    </Button>
                </>
 
              ):(
  <TextField
              fullWidth
              variant="standard"
              placeholder="Email Address"
              sx={{px:3, bgcolor:'#fff', borderRadius:'50px', py:1.3, border:'0.5px solid #D8E4FD', boxSizing:'border-box'}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        color: "#fff",
                        textTransform: "initial",
                        borderRadius: "50px",
                      width:'90px',
                      mr:-1.7
                      }}
                    >
                      Join list{" "}
                    </Button>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "50px",
                  background: "#fff",
                  fontSize:'13px'
             
         
                },
                
                disableUnderline:true
              }}
            />
              )
            }
          
          </Box>
          <Box sx={{ position: "fixed",
          bottom: 0,
          left: 0,
          right: 0, display:'grid', placeContent:'center'}}>
            <img src={img1} width={isMobile ? "500px" :"1000px"}/>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
